import React, { useState } from 'react';
import { right } from 'res/images';
import { CoreCheckBox, ReusableButton, BorderBtnWithBg } from 'whealth-core-web/components';

function DashBoardHeader(props) {
  const { data, leftData, showCheckbox, showText, style, className } = props;

  const [inputData, setInputData] = useState({ value: '' });

  const handleInputChange = (val) => {
    inputData.value = val;
    setInputData({ ...inputData });
  };

  const renderButtons = () => {
    if (data) {
      return data.map((item, index) => {
        if (item.hide) {
          return;
        }
        return (
          <div key={index}>
            <ReusableButton
              onClick={item.onClick}
              icon={item.icon}
              outline={item.outline}
              title={item.title}
              active={!!item.status}
              // style={item.style}
              style={{
                ...item.style,
                color: '#ffffff',
                padding: '15px',
                borderRadius: '8px',
                fontWeight: '500',
                fontSize: '14px',
                marginRight: '15px',
                marginLeft: '8px',
              }}
            />
          </div>
        );
      });
    } else {
      return;
    }
  };

  const leftDataMap = () => {
    return leftData.map((item, index) => {
      if (item.hide) {
        return;
      }
      if (item.showBtn) {
        return (
          <BorderBtnWithBg
            onClick={item.onClick}
            className={item.className}
            key={index}
            iconImg={item.icon}
            title={item.title}
            style={{ background: '#EBF0FF', ...item.style }}
          />
        );
      } else {
        return (
          <div key={index} className='headingTitleLink' style={item.style}>
            {item.title}
          </div>
        );
      }
    });
  };

  return (
    <div className={`d-flex align-items-center justify-content-end main-right-part-header ${className||''}`}>
      <div className='d-flex applyFilterBtn gap-15'>
        {showCheckbox && <CoreCheckBox />}
        {leftDataMap()}
      </div>
      <div className='d-flex align-items-center gap-16' style={style}>
        {showText && <div className='headerTextLite'>{showText()}</div>}
        {renderButtons()}
      </div>
    </div>
  );
}

export default DashBoardHeader;
