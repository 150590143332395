import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { CommonAlert, CoreButton, CoreInputBox, CoreTextArea, Select2DropDown } from 'whealth-core-web/components';
import './DietPlanForm.css';
import { blueOrBtn, blue_recyclebin_delete, drag_indicator } from 'res/images';
import { DateRangePicker } from 'rsuite';
import DayPicker from 'whealth-core-web/components/DayPicker';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DroppableContainer from 'whealth-core-web/components/DroppableContainer';
import DraggableItem from 'whealth-core-web/components/DraggableItem';
import DietPlanCollapse from 'whealth-core-web/components/patient/DietPlanCollapse';
import { handleTextFormatting } from 'whealth-core-web/components/Helper';

function AddDietplanForm(props) {
  const ApiClient = useApiManager();

  const [isShowAlert, setIsShowAlert] = useState('');
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  let initialFormValues = {
    diet_plans: [{ day_part_id: '', notification_time: '', recipe_link: '', regime: '' }],
  };
  const { combine, allowedMaxDays, beforeToday } = DateRangePicker;
  const [valueObj, setValueObj] = useState(initialFormValues);
  const {
    onClick,
    setErrors,
    errors,
    error,
    data,
    setIsLoading,
    isEditable,
    dietPlan,
    setDietPlan,
    templateList,
    setTemplateList,
    templateValue,
    setTemplateValue,
    searchTemplateList,
    setSearchTemplateList,
    searchDietTemplate,
    pid,
    id,
    addNewFood,
    addInputBox,
    handleSelectionChange,
    removeInputBox,
    handleChange,
    removeFormFieldForDiet,
    addDayPartForDiet,
    handleDateRange,
    handleClean,
    days,
    dietPlanIndex,
    totalDietPlans,
    deleteDietPlan,
    weekDays,
    overAllGuidelines,
    setOverAllGuidelines,
  } = props;

  const [dayPartData, setDayPartData] = useState([]);

  const [expandedPanels, setExpandedPanels] = useState([0]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpandedPanels([...expandedPanels, panel]);
    } else {
      setExpandedPanels(expandedPanels.filter((expandedPanel) => expandedPanel !== panel));
    }
  };

  useEffect(() => {
    if (dietPlan?.diet_plans?.length) {
      setExpandedPanels(dietPlan.diet_plans.map((_, index) => index));
    }
  }, [dietPlan]);

  useEffect(() => {
    if (data) {
      setValueObj({ ...data });
    }
  }, [data]);

  useEffect(() => {
    getDayPart();
    handleOnFocus();
  }, []);

  const handleOnFocus = () => {
    let params = {
      page: 1,
    };
    ApiClient.getDayParts(params)
      .then((res) => {
        let tmpData = res.data;
        // tmpData.map((item) => {
        //   item.title += ` (${formatAMPM(item.start_time)} - ${formatAMPM(item.end_time)})`;
        // });
        setDayPartData(tmpData);
      })
      .catch((err) => console.log(err));
  };

  const itrateTimeFromDayPart = (id) => {
    let singleData = dayPartData.find((item) => item.id == id);
    let startTime = singleData?.start_time;
    let endTime = singleData?.end_time;
    let timeArr = createArrayFromTime(startTime, endTime);

    return timeArr;
  };

  const formatAMPM = (time) => {
    let hours = null;
    let minutes = null;
    if (time.toString().length === 3) {
      hours = time.toString().slice(0, 1);
      minutes = time.toString().slice(1, 3);
    } else if (time.toString().length < 3) {
      minutes = time.toString().slice(0, 3);
    } else {
      hours = time.toString().slice(0, 2);
      minutes = time.toString().slice(2, 4);
    }

    let ampm = hours >= 12 && parseInt(hours) <= 23 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    let strTime = hours + ':' + (time.toString() < 10 ? '0' : '') + minutes + ' ' + ampm;
    return strTime;
  };

  const getDayPart = (query, id) => {
    if (!dietPlan.searchData) {
      dietPlan.searchData = [];
    }
    ApiClient.dayPartGet(query)
      .then((res) => {
        dietPlan.searchData[id] = res.data;
        setDietPlan({ ...dietPlan });
      })
      .catch((err) => console.log(err));
  };

  const padWithZeros = (number) => {
    let numberStr = number.toString();
    return numberStr.padStart(4, '0');
  };

  const createArrayFromTime = (start, end) => {
    const timeRange = [];
    let startLoop = parseInt(start);
    let endLoop = parseInt(end);
    if (startLoop > endLoop) {
      endLoop += 2400;
    }
    while (startLoop < endLoop) {
      if (!(startLoop % 100 == 0 || startLoop % 100 == 30)) {
        startLoop += 1;
        continue;
      }

      let nextTime = startLoop + 30;
      if (nextTime % 100 >= 60) {
        nextTime = Math.floor(nextTime / 100) * 100 + 100;
      }

      let obj = {};
      obj.title = `${formatAMPM(startLoop)} - ${formatAMPM(nextTime)}`;
      obj.id = `${String(padWithZeros(Number(startLoop) % 2400))}`;
      timeRange.push(obj);

      startLoop = nextTime;
    }
    return timeRange;
  };

  const handleDrop = (item) => {
    const { id } = item;
    const newDietPlans = [...dietPlan.diet_plans];
    const [removed] = newDietPlans.splice(id, 1);
    newDietPlans.push(removed);

    setDietPlan({ ...dietPlan, diet_plans: newDietPlans });

    const newExpandedPanels = expandedPanels.map((panelIndex) =>
      panelIndex === id ? newDietPlans.length - 1 : panelIndex > id ? panelIndex - 1 : panelIndex
    );
    setExpandedPanels(newExpandedPanels);
  };

  const formatTime = (timeValue) => {
    if (timeValue === null || timeValue === undefined || timeValue === '') {
      return { formatted: '', value: '' };
    }

    const timeStr = timeValue.toString().padStart(4, '0');

    if (timeStr.length !== 4) {
      throw new Error('Invalid time format. Expected HHMM format.');
    }

    let hours = parseInt(timeStr.substring(0, 2), 10);
    let minutes = timeStr.substring(2, 4);

    if (isNaN(hours) || isNaN(parseInt(minutes, 10))) {
      throw new Error('Invalid time format. Hours and minutes should be numeric.');
    }

    // Handle times beyond 24 hours
    hours = hours % 24;

    let period = hours < 12 ? 'AM' : 'PM';
    let displayHours = hours % 12 || 12;

    let formattedHours = displayHours.toString().padStart(2, '0');
    let startFormatted = `${formattedHours}:${minutes} ${period}`;

    let nextMinutes = (parseInt(minutes, 10) + 30) % 60;
    let nextHours = hours + (parseInt(minutes, 10) + 30 >= 60 ? 1 : 0);

    nextHours = nextHours % 24;
    let nextPeriod = nextHours < 12 ? 'AM' : 'PM';
    let nextDisplayHours = nextHours % 12 || 12;
    let nextFormattedHours = nextDisplayHours.toString().padStart(2, '0');
    let endFormatted = `${nextFormattedHours}:${nextMinutes.toString().padStart(2, '0')} ${nextPeriod}`;

    return {
      formatted: `${startFormatted} - ${endFormatted}`,
      value: `${timeStr}`,
    };
  };

  const renderDayTimeForDietPlan = (dietPlanIndex) => {
    if (dietPlan?.diet_plans) {
      return (
        <div className='w-100 mt-3'>
          {dietPlan?.diet_plans?.length ? (
            dietPlan?.diet_plans?.map((item, index) => {
              if (typeof item.day_part_id === 'object') {
                item.day_part_id = item.day_part_id?.id;
              }

              let timeData = dietPlan.diet_plans[index]?.day_part_id
                ? itrateTimeFromDayPart(dietPlan.diet_plans[index]?.day_part_id)
                : [];

              const { formatted: formattedTime, value: timeValue } = formatTime(item.notification_time || '');
              return (
                <DroppableContainer key={index} index={index} onDrop={handleDrop}>
                  <div className='border-shadow' style={{ padding: '20px 10px 20px 10px' }}>
                    <DietPlanCollapse
                      headerComponent={
                        <div className='accordion-summary-content d-flex justify-content-between align-items-center w-75'>
                          <div className='cursor d-flex align-items-center'>
                            <DraggableItem id={item.id} index={index}>
                              <img src={drag_indicator} alt='Drag indicator' />
                            </DraggableItem>

                            <div className='d-flex ms-2'>
                              <div className='me-2 display-text'>{item.day_part_title || ''}</div>
                              <div className='time-display'>{formattedTime}</div>
                            </div>
                          </div>
                          {/* <span className='view-details-text'>
                            {expandedPanels.includes(index) ? 'Hide Details' : 'View Details'}
                          </span> */}
                        </div>
                      }
                      customStyle={{ borderStyle: 'none' }}
                      customAccStyle={{ marginBottom: '0px' }}
                    >
                      <hr className='dotted-border' />
                      <div
                        key={'daypart-row' + index}
                        className='d-flex flex-column justify-content-between w-100 gap-10 mb-2'
                      >
                        <div className='d-flex justify-content-between align-items-center gap-10'>
                          <div className='d-flex gap-10' style={{ flexGrow: 1 }}>
                            <Select2DropDown
                              retuired
                              label='Day Part'
                              data={dayPartData}
                              style={{ width: '100%' }}
                              labelKey='title'
                              valueKey='id'
                              className='w-25 inputText-dd'
                              value={item?.day_part_id || ''}
                              defaultValue={dietPlan.diet_plans[index].day_part_id || ''}
                              onSearch={(query) => {
                                getDayPart(query, index);
                              }}
                              showMSG={
                                errors?.diet_plans?.[dietPlanIndex]?.diet_plan_day_parts?.[index]?.day_part_id?.[0] ||
                                ''
                              }
                              placeholder='Day Part'
                              onSelect={(data, objData) => {
                                dietPlan.diet_plans[index].day_part_id = data;
                                dietPlan.diet_plans[index].day_part_title = objData.title;
                                dietPlan.diet_plans[index].notification_time = '';
                                setDietPlan({ ...dietPlan });
                              }}
                              msgStyle={{ color: 'red' }}
                            />

                            <Select2DropDown
                              retuired
                              className='w-35 inputText-dd'
                              label='Time'
                              data={timeData}
                              style={{ width: '100%' }}
                              labelKey='title'
                              valueKey='id'
                              showMSG={
                                errors?.diet_plans?.[dietPlanIndex]?.diet_plan_day_parts?.[index]
                                  ?.notification_time?.[0] || ''
                              }
                              placeholder='Time'
                              value={timeValue}
                              onSelect={(data) => {
                                dietPlan.diet_plans[index].notification_time = data;
                                setDietPlan({ ...dietPlan });
                              }}
                              msgStyle={{ color: 'red' }}
                            />
                          </div>
                          {dietPlan?.diet_plans?.length > 1 && (
                            <img
                              className='cursor'
                              src={blue_recyclebin_delete}
                              onClick={() => removeFormFieldForDiet(index)}
                            />
                          )}
                        </div>
                        <div>
                          <div key={index}>
                            {item.diet_plan_food_items.map((set, setIndex) => (
                              <>
                                {setIndex > 0 &&
                                item.diet_plan_food_items[setIndex]?.twins !==
                                  item.diet_plan_food_items[setIndex - 1]?.twins ? (
                                  <div className='daypart_box mt-4'>
                                    <div className='d-flex justify-content-between gap-10 mt-10 mb-10'>
                                      <div className='mb-2 w-100'>
                                        <CoreInputBox
                                          retuired
                                          label='Food'
                                          value={set.food}
                                          showMSG={
                                            errors?.diet_plans?.[dietPlanIndex]?.diet_plan_day_parts?.[index]
                                              ?.diet_plan_food_items?.[setIndex]?.food?.[0] || ''
                                          }
                                          labelKey='food'
                                          placeholder='Food'
                                          style={{ width: '100%' }}
                                          className='w-100'
                                          setValue={(data) => handleChange(index, setIndex, 'food', data)}
                                          inputStyle={{ border: '1px solid #D1DCFF' }}
                                          handleKeyDown={(e) => {
                                            handleTextFormatting(e, set.food, (updatedText) => {
                                              handleChange(index, setIndex, 'food', updatedText);
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className='mb-2 w-100'>
                                        <CoreInputBox
                                          label='Quantity'
                                          value={set.quantity}
                                          labelKey='quantity'
                                          placeholder='Quantity'
                                          style={{ width: '100%' }}
                                          className='w-100'
                                          setValue={(data) => handleChange(index, setIndex, 'quantity', data)}
                                          inputStyle={{ border: '1px solid #D1DCFF' }}
                                        />
                                      </div>
                                      <div className='mb-2 w-100'>
                                        <CoreInputBox
                                          label='Recipe Link'
                                          labelKey='recipe_link'
                                          value={set.recipe_link || ''}
                                          showMSG={
                                            errors?.diet_plans?.[dietPlanIndex]?.diet_plan_day_parts?.[index]
                                              ?.diet_plan_food_items?.[setIndex]?.recipe_link?.[0] || ''
                                          }
                                          placeholder='Recipe Link'
                                          style={{ width: '100%' }}
                                          className='w-100'
                                          setValue={(data) => handleChange(index, setIndex, 'recipe_link', data)}
                                          inputStyle={{ border: '1px solid #D1DCFF' }}
                                        />
                                      </div>
                                      <div className='d-flex align-items-end day_part-btn mb-2'>
                                        <div className='me-2 cursor'>
                                          <img src={blueOrBtn} onClick={() => addInputBox(index, setIndex)} />
                                        </div>
                                        {item.diet_plan_food_items.length > 1 && (
                                          <img
                                            className='cursor'
                                            src={blue_recyclebin_delete}
                                            onClick={() => removeInputBox(index, setIndex)}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div className='daypart_box'>
                                      {setIndex > 0 && (
                                        <hr
                                          className='w-100'
                                          style={{ border: '1px dashed #C8D2ED', margin: '-14px 0px' }}
                                        />
                                      )}
                                      <div
                                        className=' d-flex justify-content-between gap-10 '
                                        style={{ marginTop: '32px' }}
                                      >
                                        <div className='mb-2 w-100'>
                                          <CoreInputBox
                                            retuired
                                            label='Food'
                                            value={set.food}
                                            showMSG={
                                              errors?.diet_plans?.[dietPlanIndex]?.diet_plan_day_parts?.[index]
                                                ?.diet_plan_food_items?.[setIndex]?.food?.[0] || ''
                                            }
                                            labelKey='food'
                                            placeholder='Food'
                                            style={{ width: '100%' }}
                                            className='w-100'
                                            setValue={(data) => handleChange(index, setIndex, 'food', data)}
                                            inputStyle={{ border: '1px solid #D1DCFF' }}
                                          />
                                        </div>
                                        <div className='mb-2 w-100'>
                                          <CoreInputBox
                                            label='Quantity'
                                            value={set.quantity}
                                            labelKey='quantity'
                                            placeholder='Quantity'
                                            style={{ width: '100%' }}
                                            className='w-100'
                                            setValue={(data) => handleChange(index, setIndex, 'quantity', data)}
                                            inputStyle={{ border: '1px solid #D1DCFF' }}
                                          />
                                        </div>
                                        <div className='mb-2 w-100'>
                                          <CoreInputBox
                                            label='Recipe Link'
                                            labelKey='recipe_link'
                                            value={set.recipe_link || ''}
                                            showMSG={
                                              errors?.diet_plans?.[dietPlanIndex]?.diet_plan_day_parts?.[index]
                                                ?.diet_plan_food_items?.[setIndex]?.recipe_link?.[0] || ''
                                            }
                                            placeholder='Recipe Link'
                                            style={{ width: '100%' }}
                                            className='w-100'
                                            setValue={(data) => handleChange(index, setIndex, 'recipe_link', data)}
                                            inputStyle={{ border: '1px solid #D1DCFF' }}
                                          />
                                        </div>
                                        <div className='d-flex align-items-end day_part-btn mb-2'>
                                          <div className='me-2 cursor'>
                                            <img src={blueOrBtn} onClick={() => addInputBox(index, setIndex)} />
                                          </div>
                                          {item.diet_plan_food_items.length > 1 && (
                                            <img
                                              className='cursor'
                                              src={blue_recyclebin_delete}
                                              onClick={() => removeInputBox(index, setIndex)}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            ))}
                          </div>
                        </div>

                        <div className='w-100'>
                          <button className='full-width-button' onClick={() => addNewFood(index)} title=''>
                            + Add Another Food Item
                          </button>
                        </div>
                        <CoreTextArea
                          showMSG={(error?.diet_plans && error?.diet_plans && error?.diet_plans?.regime) || ''}
                          labelStyle={{ fontWeight: 'var(--lightWeight)' }}
                          label={'Note'}
                          placeholder={'Enter note here'}
                          rows={5}
                          value={dietPlan?.diet_plans[index]?.note || ''}
                          inputStyle={{ border: '1px solid #D1DCFF' }}
                          setValue={(data) => {
                            dietPlan.diet_plans[index].note = data;
                            setDietPlan({ ...dietPlan });
                          }}
                          handleKeyDown={(e) =>
                            handleTextFormatting(e, dietPlan?.diet_plans[index]?.note, (updatedText) => {
                              dietPlan.diet_plans[index].note = updatedText;
                              setDietPlan({ ...dietPlan });
                            })
                          }
                        />
                      </div>

                      <div
                        className='d-flex day_part-btn gap-10 justify-content-end'
                        style={{ marginBottom: '10px', marginTop: '10px' }}
                      >
                        {dietPlan?.diet_plans?.length - 1 === index ? (
                          <button className='core-btn-form me-3' onClick={addDayPartForDiet}>
                            + Add Day Part
                          </button>
                        ) : null}
                      </div>
                    </DietPlanCollapse>
                  </div>
                </DroppableContainer>
              );
            })
          ) : (
            <div className='my-4'>
              <CoreButton outline onClick={addDayPartForDiet} title={'+ Add Day Part'} />
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderDietPlanForm = () => {
    const isLastOrOnlyDietPlan = dietPlanIndex === totalDietPlans - 1;
    return (
      <>
        <div className='card mt-3'>
          {isShowAlert && !open && (
            <div className='mt-5'>
              <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
            </div>
          )}

          <div className='card-bg border-shadow lw-rd '>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <h5 className='card-title  pt-2 pb-2 time-display'>Diet Plan {dietPlanIndex + 1}</h5>
              {totalDietPlans > 1 && (
                <img className='cursor' src={blue_recyclebin_delete} onClick={() => deleteDietPlan(dietPlanIndex)} />
              )}
            </div>

            <div className='d-flex justify-space-between pt-1'>
              <>
                <div className='w-100 me-2 inputText-dd'>
                  <Select2DropDown
                    retuired
                    className='mb-4 inputText-dd'
                    label={'Choose Template'}
                    labelKey='title'
                    valueKey={pid ? 'title' : 'id'}
                    data={searchTemplateList?.length ? searchTemplateList : templateList}
                    isValidation
                    onClean={() => {
                      setDietPlan({});
                      setTemplateValue('');
                    }}
                    value={pid ? dietPlan?.title : templateValue || ''}
                    onSearch={(searchData) => searchDietTemplate(searchData)}
                    onSelect={(val, objData) => {
                      if (objData && templateList.findIndex((item) => item.id === objData.id) === -1) {
                        templateList.push(objData);
                        setTemplateList([...templateList]);
                        setSearchTemplateList([]);
                      }

                      const selectedTemplate =
                        templateList.find((item) => item.id === val || item.title === val) || objData;

                      setTemplateValue(selectedTemplate.id);
                      setDietPlan(selectedTemplate);
                    }}
                    inputStyle={{ border: '1px solid #D1DCFF' }}
                  />
                </div>
                <div className='w-100'>
                  <div className='inputlabel'>{'Duration'}</div>
                  {pid && dietPlan?.start_date && dietPlan?.end_date && (
                    <DateRangePicker
                      shouldDisableDate={beforeToday()}
                      showOneCalendar
                      onChange={(data) => {
                        handleDateRange(data);
                      }}
                      ranges={[]}
                      placeholder='Select'
                      onClean={handleClean}
                      defaultValue={
                        dietPlan && dietPlan.start_date && dietPlan.end_date
                          ? [new Date(dietPlan.start_date), new Date(dietPlan.end_date)]
                          : null
                      }
                      style={{ width: '100%' }}
                      format='dd MMMM, yyyy'
                      calendarDefaultDate={new Date()}
                      editable={false}
                    />
                  )}
                  {pid && !dietPlan?.start_date && !dietPlan?.end_date && (
                    <DateRangePicker
                      shouldDisableDate={beforeToday()}
                      showOneCalendar
                      onChange={(data) => {
                        handleDateRange(data);
                      }}
                      ranges={[]}
                      placeholder='Select'
                      onClean={handleClean}
                      style={{ width: '100%' }}
                      format='dd MMMM, yyyy'
                      calendarDefaultDate={new Date()}
                      editable={false}
                    />
                  )}
                  {!pid && (
                    <DateRangePicker
                      shouldDisableDate={beforeToday()}
                      showOneCalendar
                      onChange={(data) => {
                        handleDateRange(data);
                      }}
                      ranges={[]}
                      placeholder='Select'
                      onClean={handleClean}
                      style={{ width: '100%' }}
                      format='dd MMMM, yyyy'
                      calendarDefaultDate={new Date()}
                      editable={false}
                    />
                  )}
                </div>
                <div className='w-100 ms-2' style={{ marginTop: -2 }}>
                  <DayPicker
                    id='weekdays'
                    label='Select Days'
                    days={days}
                    initialSelectedDays={days.filter((_, index) => dietPlan?.week_days?.includes(index))}
                    // initialSelectedDays={days?.filter((day, index) => dietTemplate?.week_days?.includes(index))}
                    onSelectionChange={(id, selectedDayIndices) => handleSelectionChange(selectedDayIndices)}
                  />
                </div>
              </>
            </div>
          </div>
          <div className='card-body border-shadow tp-rd'>
            {dietPlan?.diet_plans?.length ? (
              <>
                <div className='mt-2'>{renderDayTimeForDietPlan(dietPlanIndex)}</div>
              </>
            ) : (
              <div className='w-100 mt-4 pb-4 ps-3 no-text'>Choose Template to continue</div>
            )}
            {dietPlan?.diet_plans?.length && isLastOrOnlyDietPlan && (
              <div className='w-100 me-4'>
                <CoreTextArea
                  showMSG={(error?.diet_plans && error?.diet_plans && error?.diet_plans?.regime) || ''}
                  labelStyle={{ fontWeight: 'var(--lightWeight)', marginTop: '10px', padding: '0px 10px 0px 10px' }}
                  label={'Overall guidelines'}
                  placeholder={'Enter Guidelines'}
                  rows={5}
                  value={overAllGuidelines}
                  setValue={(data) => {
                    setOverAllGuidelines(data);
                    //dietPlan.guidelines = data;
                    setDietPlan({ ...dietPlan });
                  }}
                  inputStyle={{ border: '1px solid #D1DCFF' }}
                  handleKeyDown={(e) => handleTextFormatting(e, overAllGuidelines, setOverAllGuidelines)}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  return <div>{renderDietPlanForm()}</div>;
}

export default AddDietplanForm;
